import { companyIdAtom } from "@/atoms";
import Tooltip from "@/components/general/tooltip";
import {
    AppBar,
    Box,
    Button,
    Divider,
    SvgIcon,
    Toolbar,
    useColorScheme,
} from "@mui/material";
import { Icon } from "@theme/index";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { CompanySelect } from "../company-switcher/company-select";
import { SubNavigation } from "../megamenu/subnavigation";
import DropdownListMenu from "./dropdown-list-menu";
import HomeAppGrid from "./home-app-grid";
import MenuButton from "./menu-button";
import SearchBar from "./searchbar";

function Navigation() {
    const { i18n, t } = useTranslation("root");
    const { mode, setMode } = useColorScheme();

    const [companyId, setCompanyId] = useAtom(companyIdAtom);
    return (
        <Box sx={{ flexGrow: 1, marginBottom: 1 }}>
            <AppBar
                position="static"
                sx={(theme) => ({
                    background:
                        theme.palette.mode === "dark"
                            ? "linear-gradient(90deg, rgba(28, 57, 77, 1) 0%, rgba(21, 72, 107, 1) 100%)"
                            : "linear-gradient(90deg, rgba(0,96,161,1) 0%, rgba(0,122,202,1) 100%)",
                })}
            >
                <Toolbar>
                    <Box sx={{ paddingRight: 3 }}>
                        <SvgIcon fontSize="inherit" sx={{ fontSize: "32pt" }}>
                            <svg
                                fill="none"
                                height="44"
                                viewBox="0 0 44 44"
                                width="44"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M34.1594 9.83158C24.1244 -0.200468 20.8361 -1.16386 9.84061 9.83158C-1.19434 20.8696 -0.173201 24.1548 9.84061 34.1686C20.8421 45.1702 24.1365 44.1916 34.1594 34.1686C44.1733 24.1548 45.1974 20.8696 34.1594 9.83158ZM16.3412 28.1877C13.6425 25.8051 11.424 22.7568 13.3113 19.2589C13.8796 18.1952 14.694 17.2834 15.548 16.4386C17.2499 14.7458 19.2223 13.1837 21.1734 12.0532C23.5257 10.6217 27.0085 12.0592 26.9629 14.9069C26.9629 14.9069 26.9629 14.9069 26.9629 14.9099C26.9416 16.1195 26.2031 17.2196 25.2275 17.9004C24.2246 18.5994 21.9544 20.4775 20.8391 21.4744C19.9213 22.2949 15.6605 26.0907 19.3925 30.6676C18.3197 29.9139 17.3016 29.0812 16.3412 28.1877ZM20.912 31.6553C20.9272 31.6675 20.9394 31.6735 20.9455 31.6796C20.4288 31.3636 19.9182 31.0353 19.4198 30.6858C19.1706 30.2148 17.1375 26.0573 21.3831 23.0334C22.6656 24.2916 23.6958 24.9176 25.2215 25.9661C29.5643 29.0873 24.9176 34.3966 20.909 31.6553H20.912Z"
                                    fill="white"
                                />
                            </svg>
                        </SvgIcon>
                    </Box>

                    <Box>
                        <CompanySelect />
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                        <SearchBar />
                    </Box>

                    {companyId && (
                        <>
                            <Tooltip title={t("navigation.tooltips.close")}>
                                <Button
                                    color="inherit"
                                    onClick={() => {
                                        setCompanyId("");
                                    }}
                                    sx={{ color: "white" }}
                                >
                                    <Icon
                                        color="white"
                                        name="Reset"
                                        size="md"
                                    />
                                </Button>
                            </Tooltip>

                            <Button
                                color="inherit"
                                onClick={() => {
                                    setCompanyId("");
                                }}
                                sx={{ color: "white" }}
                            >
                                <Icon color="white" name="Home" size="md" />
                            </Button>

                            <Divider
                                flexItem
                                orientation="vertical"
                                variant="middle"
                            />
                        </>
                    )}

                    <MenuButton icon="Tool" label="HOME" variant="popover">
                        <HomeAppGrid />
                    </MenuButton>

                    <Button
                        color="inherit"
                        onClick={() => {
                            setMode(mode === "dark" ? "light" : "dark");
                        }}
                        sx={{ color: "white" }}
                    >
                        <Icon color="white" name="Color" size="md" />
                    </Button>

                    <Button
                        color="inherit"
                        onClick={async () => {
                            await i18n.changeLanguage(
                                i18n.language === "en" ? "nl" : "en",
                            );
                        }}
                        sx={{ color: "white" }}
                    >
                        <Icon color="white" name="CommentChat" size="md" />
                    </Button>

                    <DropdownListMenu
                        firstColumnMenuSections={[
                            {
                                heading: "Documentbeheer",
                                icon: "DocumentsArea",
                                items: [
                                    {
                                        label: "Documentbeheer",
                                        to: "/whatever",
                                    },
                                    { label: "Upload", to: "/whatever" },
                                    { label: "Download", to: "/whatever" },
                                ],
                            },
                        ]}
                        icon="File"
                    />
                    <DropdownListMenu
                        firstColumnMenuSections={[
                            {
                                heading: "Informatie over CASH hero",
                                icon: "Info",
                                items: [
                                    {
                                        label: "Handleiding",
                                        to: "/handleiding",
                                    },
                                    {
                                        external: true,
                                        label: "Instructievideo’s",
                                        to: "/instructionvideos",
                                    },
                                    {
                                        external: true,
                                        label: "Veel gestelde vragen",
                                        to: "/faq",
                                    },
                                    {
                                        external: true,
                                        label: "Nieuw in CashHero",
                                        to: "/newincashhero",
                                    },
                                    {
                                        label: "Stel een vraag",
                                        to: "/askquestion",
                                    },
                                    {
                                        external: true,
                                        label: "Hulp op afstand",
                                        to: "/support",
                                    },
                                    {
                                        label: "Over CashHero",
                                        to: "/about",
                                    },
                                    {
                                        external: true,
                                        label: "Controle OB-nummers",
                                        to: "/controleobnummers",
                                    },
                                ],
                            },
                        ]}
                        icon="Info"
                    />
                    <DropdownListMenu
                        firstColumnMenuSections={[
                            {
                                heading: "Loonportal",
                                icon: "Payment",
                                items: [
                                    {
                                        label: "Werkgeversportal",
                                        to: "/werkgeversportal",
                                    },
                                    {
                                        label: "Werknemersportal",
                                        to: "/werknemersportal",
                                    },
                                    {
                                        label: "Instellingen",
                                        to: "/instellingen",
                                    },
                                    {
                                        label: "LOONapp - Android",
                                        to: "/loonappandroid",
                                    },
                                    {
                                        label: "LOONapp - iOS",
                                        to: "/loonappios",
                                    },
                                ],
                            },
                        ]}
                        icon="CommentCommunity"
                        secondColumnMenuSections={[
                            {
                                heading: "Samenwerking",
                                icon: "ListView",
                                items: [
                                    { label: "Taken", to: "tasks" },
                                    { label: "Berichten", to: "messages" },
                                ],
                            },
                            {
                                heading: "Meewerkrelaties",
                                icon: "Users",
                                items: [
                                    {
                                        label: "Meewerkfunctie",
                                        to: "coopfunction",
                                    },
                                    {
                                        label: "Toevoegen klant",
                                        to: "addcustomer",
                                    },
                                    { label: "Afdelingen", to: "departments" },
                                ],
                            },
                        ]}
                    />
                    <DropdownListMenu
                        firstColumnMenuSections={[
                            {
                                heading: "Mijn voorkeuren",
                                icon: "Color",
                                items: [
                                    { label: "Uiterlijk", to: "/theme" },
                                    {
                                        label: "Private label",
                                        to: "/privatelabel",
                                    },
                                ],
                            },
                            {
                                heading: "Veiligheid",
                                icon: "Unlocked",
                                items: [
                                    {
                                        label: "Accountbeveiliging",
                                        to: "/accountsecurity",
                                    },
                                    { label: "Back-ups", to: "/backups" },
                                ],
                            },
                            {
                                heading: "Betalingen",
                                icon: "Payment",
                                items: [
                                    {
                                        label: "Bankenkoppelingen",
                                        to: "bankconnections",
                                    },
                                    { label: "AcceptEasy", to: "accepteasy" },
                                ],
                            },
                        ]}
                        icon="User"
                        name="Voornaam"
                        secondColumnMenuSections={[
                            {
                                heading: "G. E. Bruiker",
                                icon: "User",
                                items: [
                                    {
                                        icon: "Email",
                                        label: "g.bruiker@cash.nl",
                                        to: "/profile",
                                    },
                                    {
                                        icon: "ImportCompany",
                                        label: "BEDRIJF.cash.nl",
                                        to: "/profile",
                                    },
                                    { label: "Connectiviteit", to: "/profile" },
                                ],
                            },
                            {
                                heading: "Mijn account",
                                icon: "UserFolder",
                                items: [
                                    { label: "Verbruik", to: "usage" },
                                    {
                                        label: "Licentie uitbreiden",
                                        to: "expandlicense",
                                    },
                                ],
                            },
                            {
                                heading: "Gebruikers",
                                icon: "Users",
                                items: [
                                    {
                                        label: "Gebruikersoverzicht",
                                        to: "/useroverview",
                                    },
                                    {
                                        label: "E-mailadressen",
                                        to: "/emailaddresses",
                                    },
                                ],
                            },
                        ]}
                    />
                </Toolbar>
            </AppBar>
            {companyId && (
                <Box
                    sx={{
                        "& .MuiButton-root": {
                            textTransform: "none",
                        },
                    }}
                >
                    <SubNavigation />
                </Box>
            )}
        </Box>
    );
}

export default Navigation;
