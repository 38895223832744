/* eslint-disable filename-rules/match */
import { createRootRoute } from "@tanstack/react-router";

import { RootPage } from "../pages/root-page";

const rootRoute = createRootRoute({
    component: () => RootPage(),
});

export default rootRoute;
