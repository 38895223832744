import { Button, Card, CardMedia, Grid2, Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { getGlobalApiClient } from "../../api";

type Application = {
    business_unit: string;
    created_date: string;
    display_on_landing_page: boolean;
    id: string;
    identity_provider_display_name: string;
    is_first_party: boolean;
    last_login_date: string;
    login_count: number;
    login_date: string;
    logo_uri: string;
    name: string;
    require_consent: boolean;
    require_unlink_to_delete: boolean;
    sign_in_uri: string;
};

type applicationsSchema = { applications: Application[] };

export default function HomeAppGrid() {
    const { t } = useTranslation("root");
    const { user } = useAuth();
    const homeAppsQuery = useQuery({
        queryFn: async () => {
            const client = await getGlobalApiClient();
            const token = await client.get_token();
            const res = await axios.get<applicationsSchema>(
                `/public-api/v1.0/users/${user?.profile.sub}/applications`,
                {
                    headers: {
                        Authorization: `Bearer ${token.data.access_token}`,
                    },
                },
            );
            return res.data;
        },
        queryKey: ["homeApps", user?.profile.sub],
    });

    return (
        <Stack
            alignItems="center"
            direction="column"
            justifyContent="center"
            maxWidth="22em"
            padding={2}
        >
            <Grid2 container justifyContent="center" spacing={2}>
                {homeAppsQuery.isLoading && (
                    <>
                        <SkeletonGridItem />
                        <SkeletonGridItem />
                        <SkeletonGridItem />
                        <SkeletonGridItem />
                        <SkeletonGridItem />
                        <SkeletonGridItem />
                        <SkeletonGridItem />
                        <SkeletonGridItem />
                        <SkeletonGridItem />
                    </>
                )}
                {homeAppsQuery.isSuccess && (
                    <>
                        {homeAppsQuery.data.applications.map((app) => (
                            <Grid2 key={app.id}>
                                <Card
                                    sx={{ border: "none", padding: "0.5em" }}
                                    variant="outlined"
                                >
                                    <Link title={app.name} to={app.sign_in_uri}>
                                        <CardMedia
                                            alt={app.name}
                                            component="img"
                                            height="75"
                                            image={app.logo_uri}
                                            width="75"
                                        ></CardMedia>
                                    </Link>
                                </Card>
                            </Grid2>
                        ))}
                    </>
                )}
            </Grid2>
            <Button
                fullWidth
                href="https://home.visma.com"
                sx={{ marginTop: "1.2em" }}
                variant="contained"
            >
                {t("navigation.apps.all")}
            </Button>
        </Stack>
    );
}

function SkeletonGridItem() {
    return (
        <Grid2>
            <Card variant="outlined">
                <Skeleton height={75} variant="rectangular" width={75} />
            </Card>
        </Grid2>
    );
}
