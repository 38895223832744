import { List, ListItemButton } from "@mui/material";

export function SubNavigationCategoryList({
    items,
    setActiveMenu,
    setMenuContent,
}: {
    items: { active?: boolean; label: string }[];
    setActiveMenu: React.Dispatch<React.SetStateAction<null | string>>;
    setMenuContent: (category: string) => void;
}) {
    const activeStyling = {
        "&:hover": {
            background: "#2585cb",
        },
        borderRadius: "10px",
        color: "#fff",
        paddingY: "1em",
    };

    const inactiveStyling = {
        borderRadius: "10px",
        paddingY: "1em",
    };

    return (
        <List
            sx={{
                fontSize: "14px",
                fontWeight: "bold",
                overflow: "hidden",
                padding: "1em",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }}
        >
            {items.map((item) => (
                <ListItemButton
                    key={item.label}
                    onClick={() => {
                        setActiveMenu(item.label);
                        setMenuContent(item.label);
                    }}
                    sx={item.active ? activeStyling : inactiveStyling}
                >
                    {item.label}
                </ListItemButton>
            ))}
        </List>
    );
}
