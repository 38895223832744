import { atom } from "jotai";
// import { atomWithStorage } from "jotai/utils";

export const companyIdAtom = atom<string>(
    "0ae67a9f-f98c-4c63-ae99-084bdab98269",
);
export const customerIdAtom = atom<string>(
    "00000000-0000-0000-0000-000000000000",
);
export const companyNameAtom = atom<string>("Administratie naam");
export const customerNameAtom = atom<string>("Bedrijfsomgeving");
