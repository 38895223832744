import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Icon, IconName } from "@theme/index";

export default function DropdownListMenuSectionHeading({
    icon,
    label,
}: {
    icon: IconName;
    label: string;
}) {
    return (
        <ListItem>
            <ListItemIcon sx={{ minWidth: "30px" }}>
                <Icon name={icon} size="sm" />
            </ListItemIcon>
            <ListItemText
                primary={label}
                primaryTypographyProps={{
                    fontWeight: "bold",
                }}
            />
        </ListItem>
    );
}
