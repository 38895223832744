import { createRoute } from "@tanstack/react-router";

import { FormPage } from "../pages/form-page";
import rootRoute from "./__root";

const formRoute = createRoute({
    component: FormPage,
    getParentRoute: () => rootRoute,
    path: "/form/$formId",
});

export default formRoute;
