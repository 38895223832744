import { GridApi, gridFilteredSortedRowIdsSelector } from "@mui/x-data-grid";

export const getJson = (
    apiRef: React.MutableRefObject<GridApi>,
    visibleColumnsField: string[],
) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);

    // Format the data. Here we only keep the value
    return filteredSortedRowIds.map((id) => {
        const row: Record<string, unknown> = {};
        for (const field of visibleColumnsField) {
            row[field] = apiRef.current.getCellParams(id, field).value;
        }
        return row;
    });
};
