import { createRoute } from "@tanstack/react-router";

import { CompanyPage } from "../pages/company-page";
import rootRoute from "./__root";

const companyRoute = createRoute({
    component: CompanyPage,
    getParentRoute: () => rootRoute,
    path: "/company/",
});

export default companyRoute;
