import { createRoute } from "@tanstack/react-router";

import IndexPage from "../pages/index-page";
import rootRoute from "./__root";

const indexRoute = createRoute({
    component: IndexPage,
    getParentRoute: () => rootRoute,
    path: "/",
});

export default indexRoute;
