import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Grid2 } from "@mui/material";

export default function Sortable({
    children,
    id,
}: {
    children?: React.ReactNode;
    id: UniqueIdentifier;
}) {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Grid2 ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </Grid2>
    );
}
