import { ButtonProps } from "@mui/material";
import {
    GridCsvExportMenuItem,
    GridPrintExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarDensitySelector,
    GridToolbarExportContainer,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import { ExcelExportMenuItem } from "./excel-export-menu-item";

function DataGridToolbarExport(props: ButtonProps) {
    return (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem />
            <ExcelExportMenuItem />
            <GridPrintExportMenuItem />
        </GridToolbarExportContainer>
    );
}
export function DataGridToolbar(props: GridToolbarContainerProps) {
    return (
        <GridToolbarContainer {...props}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <DataGridToolbarExport />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}
