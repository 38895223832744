const dashboardRoute = "/dashboard";

const dummyMenuData = {
    Agenda: [],
    CRM: [
        {
            columns: [
                {
                    icon: "Absence3",
                    items: [
                        { label: "sdaasd", link: dashboardRoute },
                        { label: "Admasdasdin", link: "/admin" },
                        { label: "Whasadastever", link: "/whatever" },
                        { label: "Linasdasdks", link: "/links" },
                    ],
                    title: "Inkoopsdfdsfsdffacturen",
                },
                {
                    icon: "Absence2",
                    items: [
                        { label: "Grodfdsfsdotboek", link: "/groot" },
                        { label: "Kleisdfsdfnboek", link: "/klein" },
                        { label: "Sci-sdfdsffiboek", link: "/scifi" },
                        { label: "Fictsdfsdfie", link: "/fictie" },
                    ],
                    title: "Verkoopsdfsdfsdffacturen",
                },
            ],
            title: "E-facturering",
        },
    ],
    Consolidatie: [],
    Dagboeken: [],
    Dashboard: [],
    Favorieten: [],
    Financieel: [
        {
            columns: [
                {
                    icon: "ListView",
                    items: [
                        { label: "Grootboekmutaties", link: dashboardRoute },
                        { label: "Grootboekoverzicht", link: "/admin" },
                        { label: "Dagboekoverzicht", link: "/whatever" },
                        { label: "Mutatietotalen", link: "/links" },
                        { label: "Transitoria", link: "/links" },
                        { label: "Negatief dagboeksaldo", link: "/links" },
                        { label: "Saldo tegenrekening", link: "/links" },
                        { label: "Mutatiesaldi per boekdatum", link: "/links" },
                        {
                            label: "Laatste boekstuk per dagboek",
                            link: "/links",
                        },
                        { label: "WKR rapportage", link: "/links" },
                    ],
                    title: "Mutatie-overzichten",
                },
                {
                    icon: "ListView",
                    items: [
                        { label: "Bedrijfstatus", link: dashboardRoute },
                        { label: "Proef & saldibalans", link: "/admin" },
                        { label: "Winst & Verlies", link: "/whatever" },
                        { label: "Balans", link: "/links" },
                        { label: "Balansspecificatie", link: "/links" },
                        { label: "Maatwerkoverzicht", link: "/links" },
                        { label: "Kostenplaatsoverzicht", link: "/links" },
                        { label: "Resultaat p / week", link: "/links" },
                        { label: "Resultaat p / kwartaal", link: "/links" },
                        { label: "Omzetstatistiek", link: "/links" },
                        {
                            menu: [
                                { label: "SubItem1", link: "/subitem1" },
                                { label: "SubItem2", link: "/subitem2" },
                            ],
                        },
                    ],
                    title: "Periode-overzichten",
                },
                {
                    icon: "Cogwheel",
                    items: [
                        {
                            label: "Administratiegegevens",
                            link: dashboardRoute,
                        },
                        { label: "Formulierlayouts", link: "/admin" },
                        { label: "Maatwerkindelingen", link: "/whatever" },
                        { label: "Bestandindelingen", link: "/links" },
                        { label: "Betalingskenmerken", link: "/links" },
                        {
                            label: "Referentie Grootboek Schema",
                            link: "/links",
                        },
                        { label: "Audit-overzicht", link: "/links" },
                    ],
                    title: "Parameters",
                },
            ],
            title: "Overzichten",
        },
        {
            columns: [
                {
                    icon: "ListView",
                    items: [
                        { label: "Facturen", link: dashboardRoute },
                        { label: "Aanmanen", link: "/admin" },
                        { label: "Aanmaanadvies", link: "/whatever" },
                        { label: "Aanmaanhistorie", link: "/links" },
                        { label: "Debiteurenbewaking", link: "/admin" },
                        { label: "Relatiestatistiek", link: "/whatever" },
                        { label: "Totalen excl. btw", link: "/links" },
                        {
                            label: "Totalen per boekjaar (incl. btw)",
                            link: "/admin",
                        },
                        { label: "Uitsplitsen relaties", link: "/whatever" },
                    ],
                    title: "Overzichten",
                },
                {
                    icon: "OutgoingInProgress",
                    items: [
                        { label: "Aanmaanrobot", link: "/groot" },
                        {
                            label: "Aanmaningsteksten aanmaanrobot",
                            link: "/klein",
                        },
                        { label: "Testfunctie aanmaanrobot", link: "/scifi" },
                    ],
                    title: "Aanmaanrobot",
                },
            ],
            title: "Debiteuren & Crediteuren",
        },
        {
            columns: [
                {
                    icon: "PriceEuro",
                    items: [
                        { label: "Afschrijvingen", link: dashboardRoute },
                        { label: "Periodieke boekingen", link: "/admin" },
                        { label: "Verdeelsleutels", link: "/whatever" },
                        { label: "Boekstuk verplaatsen", link: "/links" },
                        { label: "Boekstuk(ken) kopiëren", link: "/admin" },
                        {
                            label: "Boekstuk(ken) verwijderen",
                            link: "/whatever",
                        },
                        {
                            label: "Afboeken betalingsverschillen",
                            link: "/links",
                        },
                        { label: "Afletteren creditnota's", link: "/admin" },
                        { label: "Afboeken webdebiteuren", link: "/whatever" },
                    ],
                    title: "Financiële functies",
                },
                {
                    icon: "History",
                    items: [
                        { label: "Aanmaanrobot", link: "/groot" },
                        {
                            label: "Aanmaningsteksten aanmaanrobot",
                            link: "/klein",
                        },
                        { label: "Testfunctie aanmaanrobot", link: "/scifi" },
                    ],
                    title: "W.I.F",
                },
                {
                    icon: "Approved",
                    items: [
                        { label: "Fiatteren", link: "/groot" },
                        { label: "Nog te fiatteren", link: "/klein" },
                        { label: "Historie fiattering", link: "/scifi" },
                    ],
                    title: "Fiatteren",
                },
            ],
            title: "Financiële functies",
        },
        {
            columns: [
                {
                    icon: "SalesInvoice",
                    items: [
                        {
                            label: "Inlezen bankafschriften",
                            link: dashboardRoute,
                        },
                        {
                            label: "Inlezen CAMT053 formaat",
                            link: dashboardRoute,
                        },
                        {
                            label: "Ophalen bankenkoppeling",
                            link: dashboardRoute,
                        },
                        { label: "Matchen bankmutaties", link: dashboardRoute },
                        {
                            label: "Her-matchen bankmutaties",
                            link: dashboardRoute,
                        },
                        { label: "Verslag bankmutaties", link: dashboardRoute },
                        {
                            label: "Bij- en afschrijvingen",
                            link: dashboardRoute,
                        },
                        { label: "Bank Dashboard", link: dashboardRoute },
                    ],
                    title: "Bankbestanden",
                },
                {
                    icon: "CashFlow",
                    items: [
                        { label: "Advies op nummer", link: dashboardRoute },
                        { label: "Advies op naam", link: dashboardRoute },
                        {
                            label: "Advies op factuurnummer",
                            link: dashboardRoute,
                        },
                        {
                            label: "Ovz. advies binnen SEPA",
                            link: dashboardRoute,
                        },
                        {
                            label: "Ovz. advies buiten SEPA",
                            link: dashboardRoute,
                        },
                        {
                            label: "Aanmaken opdracht binnen SEPA",
                            link: dashboardRoute,
                        },
                        {
                            label: "Aanmaken opdracht BTL91",
                            link: dashboardRoute,
                        },
                        {
                            label: "Aanmaken opdracht Generic Payment",
                            link: dashboardRoute,
                        },
                        {
                            label: "Opvragen status ING betaal",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Betaalopdrachten",
                },
                {
                    icon: "CashFlow",
                    items: [
                        { label: "Incasso advies", link: dashboardRoute },
                        { label: "Incasso-overzicht", link: dashboardRoute },
                        {
                            label: "Incasso-opdracht maken",
                            link: dashboardRoute,
                        },
                        {
                            label: "Terugdraaien incasso SSD",
                            link: dashboardRoute,
                        },
                        { label: "Maak machtiging", link: dashboardRoute },
                        {
                            label: "Overzicht machtigingen",
                            link: dashboardRoute,
                        },
                        {
                            label: "Actieve bankenkoppelingen",
                            link: dashboardRoute,
                        },
                        {
                            label: "Aanpassen stuknummers",
                            link: dashboardRoute,
                        },
                        {
                            label: "Zoeken op IBAN telebanking",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Incasso-opdrachten",
                },
            ],
            title: "Betalingsverkeer",
        },
        {
            columns: [
                {
                    icon: "SalesInvoice",
                    items: [
                        {
                            label: "Inlezen bankafschriften",
                            link: dashboardRoute,
                        },
                        {
                            label: "Inlezen CAMT053 formaat",
                            link: dashboardRoute,
                        },
                        {
                            label: "Ophalen bankenkoppeling",
                            link: dashboardRoute,
                        },
                        { label: "Matchen bankmutaties", link: dashboardRoute },
                        {
                            label: "Her-matchen bankmutaties",
                            link: dashboardRoute,
                        },
                        { label: "Verslag bankmutaties", link: dashboardRoute },
                        {
                            label: "Bij- en afschrijvingen",
                            link: dashboardRoute,
                        },
                        { label: "Bank Dashboard", link: dashboardRoute },
                    ],
                    title: "Aangiftes OB/ICP/CBS",
                },
                {
                    icon: "CashFlow",
                    items: [
                        { label: "Advies op nummer", link: dashboardRoute },
                        { label: "Advies op naam", link: dashboardRoute },
                        {
                            label: "Advies op factuurnummer",
                            link: dashboardRoute,
                        },
                        {
                            label: "Ovz. advies binnen SEPA",
                            link: dashboardRoute,
                        },
                        {
                            label: "Ovz. advies buiten SEPA",
                            link: dashboardRoute,
                        },
                        {
                            label: "Aanmaken opdracht binnen SEPA",
                            link: dashboardRoute,
                        },
                        {
                            label: "Aanmaken opdracht BTL91",
                            link: dashboardRoute,
                        },
                        {
                            label: "Aanmaken opdracht Generic Payment",
                            link: dashboardRoute,
                        },
                        {
                            label: "Opvragen status ING betaal",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Overzichten",
                },
                {
                    icon: "CashFlow",
                    items: [
                        { label: "Incasso advies", link: dashboardRoute },
                        { label: "Incasso-overzicht", link: dashboardRoute },
                        {
                            label: "Incasso-opdracht maken",
                            link: dashboardRoute,
                        },
                        {
                            label: "Terugdraaien incasso SSD",
                            link: dashboardRoute,
                        },
                        { label: "Maak machtiging", link: dashboardRoute },
                        {
                            label: "Overzicht machtigingen",
                            link: dashboardRoute,
                        },
                        {
                            label: "Actieve bankenkoppelingen",
                            link: dashboardRoute,
                        },
                        {
                            label: "Aanpassen stuknummers",
                            link: dashboardRoute,
                        },
                        {
                            label: "Zoeken op IBAN telebanking",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Diversen",
                },
            ],
            title: "Fiscale functies",
        },
        {
            columns: [
                {
                    icon: "SalesInvoice",
                    items: [
                        { label: "Verwerken postbus", link: dashboardRoute },
                        { label: "Uitzoekposten", link: dashboardRoute },
                        { label: "Status", link: dashboardRoute },
                        { label: "Log", link: dashboardRoute },
                        { label: "Kenmerken UBL inkoop", link: dashboardRoute },
                        { label: "Viewer UBL inkoop", link: dashboardRoute },
                        { label: "Whitelist UBL", link: dashboardRoute },
                    ],
                    title: "Inkoopfacturen",
                },
                {
                    icon: "CashFlow",
                    items: [
                        { label: "Verwerken postbus", link: dashboardRoute },
                        { label: "Ontbrekende facturen", link: dashboardRoute },
                        { label: "Status", link: dashboardRoute },
                        { label: "Log", link: dashboardRoute },
                    ],
                    title: "Verkoopfacturen",
                },
            ],
            title: "E-facturering",
        },
        {
            columns: [
                {
                    icon: "SalesInvoice",
                    items: [
                        {
                            label: "Afsluiten per dagboek",
                            link: dashboardRoute,
                        },
                        {
                            label: "Afsluiten alle dagboeken",
                            link: dashboardRoute,
                        },
                        {
                            label: "Afsluiten periode / jaar",
                            link: dashboardRoute,
                        },
                        { label: "Verwijderen mutaties", link: dashboardRoute },
                        { label: "Verwijderen saldi", link: dashboardRoute },
                        {
                            label: "Export afgesl. dagboeken",
                            link: dashboardRoute,
                        },
                        { label: "Integriteitscontrole", link: dashboardRoute },
                        {
                            label: "Beperken boekperiodes",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Afsluiten",
                },
            ],
            title: "Afsluiten",
        },
        {
            columns: [
                {
                    icon: "SalesInvoice",
                    items: [
                        { label: "ASCII (CASH-formaat)", link: dashboardRoute },
                        {
                            label: "Via 'bestandsindeling'",
                            link: dashboardRoute,
                        },
                        { label: "Auditfile 3.2", link: dashboardRoute },
                        { label: "Auditfile XML", link: dashboardRoute },
                        { label: "Auditfile (ascii)", link: dashboardRoute },
                        { label: "Relatie", link: dashboardRoute },
                        { label: "E-ports", link: dashboardRoute },
                        { label: "Speciale formaten", link: dashboardRoute },
                    ],
                    title: "Import van gegevens",
                },
                {
                    icon: "CashFlow",
                    items: [
                        { label: "ascii", link: dashboardRoute },
                        { label: "Relaties", link: dashboardRoute },
                        { label: "Mutaties", link: dashboardRoute },
                        { label: "Maatwerkoverzichten", link: dashboardRoute },
                        { label: "Rekeningschema", link: dashboardRoute },
                        { label: "Speciale formaten", link: dashboardRoute },
                        {
                            label: "Vrije rubriek naar excel",
                            link: dashboardRoute,
                        },
                        {
                            label: "Kopieer naar meewerk relaties",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Export van gegevens",
                },
                {
                    icon: "CashFlow",
                    items: [
                        {
                            label: "Excat-admin. (mutaties)",
                            link: dashboardRoute,
                        },
                        {
                            label: "Exact relbeh. (DOS V7)",
                            link: dashboardRoute,
                        },
                        {
                            label: "Snelstart-admin. (DOS)",
                            link: dashboardRoute,
                        },
                        { label: "Afas-admin. (DOS)", link: dashboardRoute },
                        {
                            label: "Aanmaken synchronisatie",
                            link: dashboardRoute,
                        },
                        {
                            label: "Inlezen synchronisatie",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Conversies",
                },
            ],
            title: "Import / export / conversies",
        },
        {
            columns: [
                {
                    icon: "SalesInvoice",
                    items: [
                        {
                            label: "Renteberekening grootboeksaldi",
                            link: dashboardRoute,
                        },
                        {
                            label: "Terugdraaien aanmaning",
                            link: dashboardRoute,
                        },
                        {
                            label: "Conversie telefoonnrs",
                            link: dashboardRoute,
                        },
                        {
                            label: "Activeren zoekwoorden",
                            link: dashboardRoute,
                        },
                        {
                            label: "De-activeren zoekwoorden",
                            link: dashboardRoute,
                        },
                        {
                            label: "Telling dagboekmutaties",
                            link: dashboardRoute,
                        },
                        {
                            label: "Conversie aantal periodes",
                            link: dashboardRoute,
                        },
                        {
                            label: "Verwijderen Auditrecords",
                            link: dashboardRoute,
                        },
                        {
                            label: "Ontbrekende PDF's in archief",
                            link: dashboardRoute,
                        },
                        {
                            label: "Saldi startpagina vernieuwen",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Diversen",
                },
                {
                    icon: "CashFlow",
                    items: [
                        {
                            label: "Koppelen gbrek aan subadmin",
                            link: dashboardRoute,
                        },
                        {
                            label: "Koppelen relatie aan subadmin",
                            link: dashboardRoute,
                        },
                        { label: "Ontkoppelen subadmin", link: dashboardRoute },
                        { label: "Overzichten", link: dashboardRoute },
                        { label: "Saldi-overzicht", link: dashboardRoute },
                        { label: "Mutatielijst", link: dashboardRoute },
                        { label: "Specificatie", link: dashboardRoute },
                    ],
                    title: "Subadministratie",
                },
                {
                    icon: "CashFlow",
                    items: [
                        {
                            label: "Referentie Grootboekschema",
                            link: dashboardRoute,
                        },
                        { label: "Balans RGS", link: dashboardRoute },
                        { label: "Winst & Verlies RGS", link: dashboardRoute },
                    ],
                    title: "RGS",
                },
            ],
            title: "Diversen",
        },
        {
            columns: [
                {
                    icon: "SalesInvoice",
                    items: [
                        { label: "Dagboekcodes", link: dashboardRoute },
                        { label: "Taalcodes", link: dashboardRoute },
                        { label: "Valutacodes", link: dashboardRoute },
                        { label: "Afschrijvingen", link: dashboardRoute },
                        { label: "Periodieke boekingen", link: dashboardRoute },
                        { label: "Verdeelsleutels", link: dashboardRoute },
                        { label: "Medewerkers", link: dashboardRoute },
                        { label: "Functies", link: dashboardRoute },
                        { label: "WKR-percentages", link: dashboardRoute },
                        { label: "Onderhoud budgetten", link: dashboardRoute },
                        {
                            label: "Verwijderen budgetten",
                            link: dashboardRoute,
                        },
                        { label: "Kopiëren budgetten", link: dashboardRoute },
                    ],
                    title: "Stamgegevens",
                },
                {
                    icon: "CashFlow",
                    items: [
                        { label: "Grootboekrekeningen", link: dashboardRoute },
                        { label: "Kostenplaatsen", link: dashboardRoute },
                        { label: "Balanscodes", link: dashboardRoute },
                        { label: "Winst & verliescodes", link: dashboardRoute },
                        { label: "Subadmin. kenmerken", link: dashboardRoute },
                        {
                            label: "Verwijderen stamgegevens",
                            link: dashboardRoute,
                        },
                        {
                            label: "Overnemen stamgegevens",
                            link: dashboardRoute,
                        },
                        {
                            label: "Omcoderen stamgegevens",
                            link: dashboardRoute,
                        },
                    ],
                    title: "Grootboekrekeningen",
                },
                {
                    icon: "ListView",
                    items: [
                        { label: "Relatielijst", link: dashboardRoute },
                        { label: "Etiketten", link: dashboardRoute },
                        { label: "Relatiestambladen", link: dashboardRoute },
                        { label: "Adreslijst", link: dashboardRoute },
                        { label: "Rekeningschema", link: dashboardRoute },
                        { label: "Dagboekcodes", link: dashboardRoute },
                        { label: "Kostenplaatsen", link: dashboardRoute },
                        { label: "Balanscodes", link: dashboardRoute },
                        { label: "Taalcodes", link: dashboardRoute },
                        { label: "Valutacodes", link: dashboardRoute },
                        { label: "Budgetten", link: dashboardRoute },
                        { label: "Afschrijvingen", link: dashboardRoute },
                    ],
                    title: "Overzichten",
                },
            ],
            title: "Stamgegevens",
        },
    ],
    Projecten: [],
    Relaties: [],
};

export default dummyMenuData;
