// VISMA palette
// https://www.vismaspcs.se/designsystem/colour
export type ColorValueHex = `#${string}`;
export type ColorSet = Record<number, ColorValueHex>;

export const lightNeutral: ColorSet = {
    50.1: "#FFFFFF",
    50.2: "#FFFFFF",
    50.3: "#FFFFFF",
    50.4: "#FFFFFF",
    50.5: "#FFFFFF",
    100.1: "#F7F7F7",
    100.2: "#F7F7F7",
    125: "#D9D9D9",
    150: "#F0F2F5",
    300: "#DADADA",
    400: "#C0C0C0",
    500: "#A5A5A5",
    600: "#8A8A8A",
    700: "#6B6B6B",
    800: "#494A4A",
    900: "#252626",
};

export const darkNeutral: ColorSet = {
    50.1: "#15181A",
    50.2: "#2B3033",
    50.3: "#E4EBF0",
    50.4: "#6F7A82",
    50.5: "#4C545B",
    100.1: "#4C545B",
    100.2: "#15181A",
    125: "#D9D9D9",
    150: "#1E2123",
    300: "#6F7A82",
    400: "#4C545B",
    500: "#86929B",
    600: "#6F7A82",
    700: "#AFBCC4",
    800: "#C3D0D9",
    900: "#E4EBF0",
};

export const lightBlue: ColorSet = {
    50.1: "#E7F2F9",
    50.2: "#E7F2F9",
    75: "#EDF2FA",
    100: "#D2EAFA",
    200.1: "#99CBEC",
    200.2: "#99CBEC",
    300: "#6CB2E1",
    400: "#419BD7",
    500.1: "#1482CC",
    500.2: "#1482CC",
    500.3: "#1482CC",
    600: "#116FAE",
    700.1: "#0D5788",
    700.2: "#0D5788",
    800: "#14476A",
    900: "#1B384C",
};

export const darkBlue: ColorSet = {
    50.1: "#1B384C",
    50.2: "#6F7A82",
    75: "#2B3033",
    100: "#2B3033",
    200.1: "#14476A",
    200.2: "#86929B",
    300: "#419BD7",
    400: "#C3D0D9",
    500.1: "#1482CC",
    500.2: "#116FAE",
    500.3: "#6CB2E1",
    600: "#E4EBF0",
    700.1: "#1482CC",
    700.2: "#15181A",
    800: "#E7F2F9",
    900: "#14476A",
};

export const lightRed: ColorSet = {
    50.1: "#FFEDEF",
    50.2: "#FFEDEF",
    100: "#FAD9DE",
    200: "#F1BDC4",
    300: "#EAA1AC",
    400: "#E28594",
    500: "#DB6B7D",
    600: "#D44F64",
    700: "#CC334C",
    800: "#A12036",
    900: "#801F30",
};

export const darkRed: ColorSet = {
    50.1: "#801F30",
    50.2: "#2B3033",
    100: "#4C545B",
    200: "#801F30",
    300: "#E28594",
    400: "#A12036",
    500: "#DB6B7D",
    600: "#DB6B7D",
    700: "#DB6B7D",
    800: "#EAA1AC",
    900: "#FFEDEF",
};

export const lightGreen: ColorSet = {
    50: "#EBF7EB",
    100: "#CFE7CF",
    200: "#AFD4AF",
    300: "#9BC29B",
    400: "#7FAF7F",
    500: "#67A870",
    600: "#4E9C54",
    700: "#2D7048",
    800: "#316245",
    900.1: "#254934",
    900.2: "#254934",
};

export const darkGreen: ColorSet = {
    50: "#254934",
    100: "#CFE7CF",
    200: "#316245",
    300: "#7FAF7F",
    400: "#7FAF7F",
    500: "#67A870",
    600: "#67A870",
    700: "#4E9C54",
    800: "#67A870",
    900.1: "#316245",
    900.2: "#EBF7EB",
};

export const lightOrange: ColorSet = {
    50: "#FCF2DC",
    100: "#FCDFB1",
    200: "#FAD7A2",
    300: "#F2C480",
    400: "#EDB45F",
    500: "#E5A23E",
    600: "#CA7900",
    700: "#C26800",
    800: "#B35300",
    900: "#803B00",
};

export const darkOrange: ColorSet = {
    50: "#803B00",
    100: "#FCDFB1",
    200: "#FAD7A2",
    300: "#EDB45F",
    400: "#EDB45F",
    500: "#E5A23E",
    600: "#E5A23E",
    700: "#C26800",
    800: "#B35300",
    900: "#FCF2DC",
};

export const lightPurple: ColorSet = {
    50: "#EDECF5",
    100: "#DAD7EA",
    200: "#C6C1DF",
    300: "#B2ABD3",
    400: "#9E96C8",
    500: "#8A80BD",
    600: "#7365B8",
    700: "#6154A5",
    800: "#4D3D99",
    900: "#403380",
};

export const darkPurple: ColorSet = {
    50: "#EDECF5",
    100: "#DAD7EA",
    200: "#C6C1DF",
    300: "#B2ABD3",
    400: "#9E96C8",
    500: "#9E96C8",
    600: "#7365B8",
    700: "#6154A5",
    800: "#4D3D99",
    900: "#403380",
};
