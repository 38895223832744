import { Grid2, Menu, Paper, Stack } from "@mui/material";
import { useState } from "react";
import { z } from "zod";

import dummyMenuData from "./dummydata";
import { MegaMenuColumn } from "./megamenu-column";
import { SubNavigationCategoryList } from "./subnavigation-categorylist";
import { SubNavigationTopMenuButton } from "./subnavigation-topmenu-button";

export const MenuLinkSchema = z.object({
    label: z.string(),
    link: z.string(),
});

export const InlineMenuSchema = z.object({ menu: z.array(MenuLinkSchema) });

export const ColumnMenuSchema = z.object({
    icon: z.string(), //TODO: IconSchema
    items: z.array(z.union([MenuLinkSchema, InlineMenuSchema])),
    title: z.string().optional(),
});

export const SubMenuSchema = z.array(ColumnMenuSchema);

export const SubMenuCategorySchema = z.object({
    columns: SubMenuSchema,
    title: z.string(),
});

/**
 * Represents the schema for the sub-navigation component.
 *
 * @remarks
 * This schema defines the structure of the sub-navigation component, including the titles and menus.
 */
export const SubNavigationSchema = z.record(z.array(SubMenuCategorySchema));

export function SubNavigation() {
    const menuData = SubNavigationSchema.parse(dummyMenuData);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(
        null,
    );
    const [activeMenu, setActiveMenu] = useState<null | string>(null);
    const [categoryKey, setCategoryKey] =
        useState<keyof typeof menuData>("Financieel");
    const [menuContent, setMenuContent] = useState<null | z.infer<
        typeof SubMenuSchema
    >>(null);
    const setMenuContentByKey = (category: string) => {
        setMenuContent(
            (
                menuData[categoryKey].find(({ title }) => title === category) ??
                menuData[categoryKey][0]
            ).columns,
        );
    };
    const open = Boolean(anchorElement);
    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        key: keyof typeof menuData,
    ) => {
        const menuContent =
            menuData[key].find((predicate) => predicate.title == activeMenu) ??
            menuData[key][0];
        setMenuContent(menuContent.columns);
        setActiveMenu(menuContent.title);
        setAnchorElement(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorElement(null);
    };

    return (
        <>
            <Paper elevation={3} square sx={{ flexGrow: 1, height: "48px" }}>
                <Stack direction={"row"}>
                    {Object.keys(menuData).map((key: string) => (
                        <SubNavigationTopMenuButton
                            dropdown={menuData[key] != null}
                            key={key}
                            label={key}
                            onClick={(event) => {
                                setCategoryKey(key);
                                handleClick(event, key);
                            }}
                        />
                    ))}
                </Stack>
            </Paper>
            <Menu
                anchorEl={anchorElement}
                id="subnav"
                onClose={handleClose}
                open={open}
            >
                <Grid2 container spacing={1} sx={{ minWidth: "960px" }}>
                    <Grid2 size={3}>
                        <SubNavigationCategoryList
                            items={menuData[categoryKey].map((category) => {
                                return {
                                    active: category.title === activeMenu,
                                    label: category.title,
                                };
                            })}
                            setActiveMenu={setActiveMenu}
                            setMenuContent={setMenuContentByKey}
                        />
                    </Grid2>
                    {menuContent?.map(
                        (column: z.infer<typeof ColumnMenuSchema>) => (
                            <Grid2 key={column.title} size={3}>
                                <MegaMenuColumn column={column} />
                            </Grid2>
                        ),
                    )}
                </Grid2>
            </Menu>
        </>
    );
}
