import { createRoute } from "@tanstack/react-router";

import { OverviewPage } from "../pages/overview-page";
import rootRoute from "./__root";

const overviewRoute = createRoute({
    component: OverviewPage,
    getParentRoute: () => rootRoute,
    path: "/overview/$type",
});

export default overviewRoute;
