import { PaletteOptions } from "@mui/material";

import {
    darkBlue,
    darkGreen,
    darkNeutral,
    darkOrange,
    darkPurple,
    darkRed,
    lightBlue,
    lightGreen,
    lightNeutral,
    lightOrange,
    lightPurple,
    lightRed,
} from "./colors";

export const lightPalette: PaletteOptions = {
    action: {
        activatedOpacity: 0.12,
        active: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        hover: "rgba(0, 0, 0, 0.04)",
        hoverOpacity: 0.04,
        selected: "rgba(0, 0, 0, 0.08)",
        selectedOpacity: 0.08,
    },
    background: {
        default: lightNeutral[50.1],
        paper: lightNeutral[50.2],
    },
    common: {
        black: "#000",
        white: "#fff",
    },
    divider: lightNeutral[50.3],
    error: {
        contrastText: "#fff",
        dark: lightRed[800],
        light: lightRed[400],
        main: lightRed[600],
    },
    grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#424242",
        900: "#212121",
        A100: "#f5f5f5",
        A200: "#eeeeee",
        A400: "#bdbdbd",
        A700: "#616161",
    },
    info: {
        contrastText: lightNeutral[50.5],
        dark: lightNeutral[700],
        light: lightNeutral[300],
        main: lightNeutral[600],
    },
    mode: "light",
    primary: {
        contrastText: "#fff",
        dark: lightBlue[700.1],
        light: lightBlue[200.1],
        main: lightBlue[500.2],
    },
    secondary: {
        contrastText: "#fff",
        dark: lightPurple[700],
        light: lightPurple[500],
        main: lightPurple[300],
    },
    success: {
        contrastText: "#fff",
        dark: lightGreen[900.1],
        light: lightGreen[600],
        main: lightGreen[700],
    },
    text: {
        disabled: lightNeutral[600],
        primary: lightNeutral[900],
        secondary: lightNeutral[800],
    },
    warning: {
        contrastText: "#fff",
        dark: lightOrange[900],
        light: lightOrange[300],
        main: lightOrange[600],
    },
};

export const darkPalette: PaletteOptions = {
    action: {
        activatedOpacity: 0.24,
        active: "#fff",
        disabled: "rgba(255, 255, 255, 0.3)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(255, 255, 255, 0.12)",
        focusOpacity: 0.12,
        hover: "rgba(255, 255, 255, 0.08)",
        hoverOpacity: 0.08,
        selected: "rgba(255, 255, 255, 0.16)",
        selectedOpacity: 0.16,
    },
    background: {
        default: darkNeutral[50.1],
        paper: darkNeutral[50.1],
    },
    common: {
        black: "#000",
        white: "#fff",
    },
    divider: darkNeutral[50.3],
    error: {
        contrastText: "#fff",
        dark: darkRed[800],
        light: darkRed[400],
        main: darkRed[600],
    },
    grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#424242",
        900: "#212121",
        A100: "#f5f5f5",
        A200: "#eeeeee",
        A400: "#bdbdbd",
        A700: "#616161",
    },
    info: {
        contrastText: "rgba(0, 0, 0, 0.87)",
        dark: darkNeutral[700],
        light: darkNeutral[300],
        main: darkNeutral[600],
    },
    mode: "dark",
    primary: {
        contrastText: "#fff",
        dark: darkBlue[700.1],
        light: darkBlue[200.1],
        main: darkBlue[500.2],
    },
    secondary: {
        contrastText: "#fff",
        dark: darkPurple[700],
        light: darkPurple[500],
        main: darkPurple[300],
    },
    success: {
        contrastText: "#fff",
        dark: darkGreen[900.1],
        light: darkGreen[600],
        main: darkGreen[700],
    },
    text: {
        disabled: darkNeutral[600],
        primary: darkNeutral[900],
        secondary: darkNeutral[800],
    },
    warning: {
        contrastText: "#fff",
        dark: darkOrange[900],
        light: darkOrange[300],
        main: darkOrange[600],
    },
};
