import { Icon, IconName } from "@/theme";
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { createLink } from "@tanstack/react-router";

import DropdownListMenuSectionHeading from "./dropdown-list-menu-section-heading";

export type MenuSectionProps = {
    heading: string;
    icon: IconName;
    items: itemProps[];
};

type itemProps = {
    external?: boolean;
    icon?: IconName;
    label: string;
    to: string;
};

const RouterButton = createLink(ListItemButton);

export default function DropdownListMenuSection({
    heading,
    icon,
    items,
}: MenuSectionProps) {
    return (
        <List dense>
            <DropdownListMenuSectionHeading icon={icon} label={heading} />
            {items.map((item) => (
                <RouterButton
                    key={item.label}
                    sx={{ paddingLeft: "3em" }}
                    to={item.to}
                >
                    {item.icon && (
                        <ListItemIcon sx={{ minWidth: "36px", padding: 0 }}>
                            <Icon name={item.icon} size="sm" />
                        </ListItemIcon>
                    )}
                    <ListItemText primary={item.label} />
                    {item.external && (
                        <ListItemIcon sx={{ minWidth: "36px" }}>
                            <Icon name="Export" size="sm" />
                        </ListItemIcon>
                    )}
                </RouterButton>
            ))}
        </List>
    );
}
