import { Box, Button, Stack } from "@mui/material";

import { Icon } from "../../theme/icon";

export function SubNavigationTopMenuButton({
    active,
    dropdown,
    label,
    onClick,
}: {
    active?: boolean;
    dropdown?: boolean;
    label: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
    return (
        <Button
            color={active ? "primary" : "inherit"}
            onClick={onClick}
            sx={{
                fontSize: "14px",
                height: "48px",
                paddingX: "1em",
                textTransform: "none",
            }}
            variant="text"
        >
            <Stack
                alignItems="center"
                alignSelf={"center"}
                direction="row"
                spacing={1}
            >
                <Box>{label}</Box>
                {dropdown && (
                    <Box sx={{ height: "24px" }}>
                        <Icon
                            name={active ? "ChevronUp" : "ChevronDown"}
                            size="sm"
                        />
                    </Box>
                )}
            </Stack>
        </Button>
    );
}
