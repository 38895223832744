import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    MouseSensor,
    UniqueIdentifier,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    rectSortingStrategy,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import {
    Box,
    Button,
    Divider,
    Drawer,
    Grid2,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { Icon, IconName } from "@theme/index";
import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";

import AdministrationPickerWidget from "../components/widgets/administration-picker-widget";
import AdministrationStatusWidget from "../components/widgets/administration-status-widget";
import BTWWidget from "../components/widgets/btw-widget";
import Draggable from "../components/widgets/draggable";
import Sortable from "../components/widgets/sortable";
import WelcomeWidget from "../components/widgets/welcome-widget";

type Widget = { component: React.ReactNode; id: UniqueIdentifier };

export default function IndexPage() {
    const [items, setItems] = useState<Widget[]>([
        { component: <WelcomeWidget />, id: 1 },
        { component: <AdministrationPickerWidget />, id: 2 },
        { component: <BTWWidget />, id: 3 },
        { component: <AdministrationStatusWidget />, id: 4 },
    ]);
    const [widgetDrawerOpen, setwidgetDrawerOpen] = useState(false);

    const sensors = useSensors(
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 20,
            },
        }),
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            setItems((items) => {
                const oldIndex = items.findIndex(
                    (item) => item.id === active.id,
                );
                const newIndex = items.findIndex(
                    (item) => item.id === over?.id,
                );

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    return (
        <>
            <Box
                sx={(theme) => ({
                    backgroundImage:
                        theme.palette.mode === "dark"
                            ? "url(/assets/cash-logo-bg-dark.svg)"
                            : "url(/assets/cash-logo-bg.svg)",
                    backgroundPosition: "top",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "calc(100vh - 64px - 48px - 10px)", // 64px = AppBar, 48px = BottomNavigation, 10px = padding
                    overflow: "auto",
                })}
            >
                <Grid2
                    container
                    gap={3}
                    paddingTop={2.5}
                    paddingX={2}
                    sx={{ backgroundColor: "inherit" }}
                >
                    <Grid2 size={12} sx={{ backgroundColor: "inherit" }}>
                        <DrawerButtonGrid
                            setwidgetDrawerOpen={setwidgetDrawerOpen}
                        />
                    </Grid2>
                    <DndContext
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        sensors={sensors}
                    >
                        <Drawer
                            anchor="right"
                            hideBackdrop
                            onClose={() => setwidgetDrawerOpen(false)}
                            open={widgetDrawerOpen}
                        >
                            <DrawerList toggleDrawer={setwidgetDrawerOpen} />
                        </Drawer>
                        <SortableContext
                            items={items}
                            strategy={rectSortingStrategy}
                        >
                            {items.map((widget) => (
                                <Sortable id={widget.id} key={widget.id}>
                                    {widget.component}
                                </Sortable>
                            ))}
                        </SortableContext>
                    </DndContext>
                </Grid2>
            </Box>
        </>
    );
}

function DrawerList({
    toggleDrawer,
}: {
    toggleDrawer: Dispatch<React.SetStateAction<boolean>>;
}) {
    return (
        <Box
            onClick={() => toggleDrawer(false)}
            role="presentation"
            sx={{ width: 250 }}
        >
            <Typography sx={{ textAlign: "center" }} variant="h5">
                Widgets
            </Typography>
            <Divider />
            <List>
                {["News", "TileView"].map((text) => (
                    <Draggable id={text} key={text}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Icon name={text as IconName} size="sm" />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    </Draggable>
                ))}
            </List>
        </Box>
    );
}

function DrawerButtonGrid({
    setwidgetDrawerOpen,
}: {
    setwidgetDrawerOpen: Dispatch<React.SetStateAction<boolean>>;
}) {
    const { t } = useTranslation();
    return (
        <Grid2
            alignItems="flex-start"
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ backgroundColor: "inherit" }}
        >
            <Grid2 sx={{ backgroundColor: "inherit" }}>
                <Button
                    color="secondary"
                    onClick={() => setwidgetDrawerOpen(true)}
                    size="small"
                >
                    <Icon name="Edit" size="sm" />
                    <span style={{ marginLeft: "0.25em" }}>
                        {t("index:DrawerButtonGrid.label")}
                    </span>
                </Button>
            </Grid2>
        </Grid2>
    );
}
