// eslint-disable-next-line filename-rules/match
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "./locales/resources";

void i18n.use(initReactI18next).init({
    debug: true,
    defaultNS: "general",
    interpolation: {
        escapeValue: false,
    },
    lng: "en",
    ns: [
        "general",
        "index",
        "widgets",
        "company",
        "form",
        "login",
        "overview",
        "root",
    ],
    resources: {
        en: resources.en,
        nl: resources.nl,
    },
});

export { default } from "i18next";
