import { Box, Grid2 } from "@mui/material";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useState } from "react";

import { Components } from "../../commonapi.openapi";
import { getCommonApiClient } from "../api";
import { companyIdAtom, customerIdAtom } from "../atoms";
import { DataGridToolbar } from "../components/datagrid/datagrid-toolbar";
import { useDataGridColumns } from "../components/datagrid/use-datagrid-columns";
import overviewRoute from "../routes/overview";

export function OverviewPage() {
    const customer = useAtomValue(customerIdAtom);
    const company = useAtomValue(companyIdAtom);
    const { type } = overviewRoute.useParams();
    const columns = useDataGridColumns<Components.Schemas.FunctionTypeResponse>(
        { company: company, customer: customer },
    );

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
        {
            page: 0,
            pageSize: 10,
        },
    );

    const overviewQuery = useQuery({
        queryFn: async () => {
            const client = await getCommonApiClient();
            const res = await client.get_function_types({
                cash_customer: customer,
                company_id: company,
                page: Math.max(1, paginationModel.page + 1),
                per_page: Math.max(paginationModel.pageSize, 5),
            });
            return res.data;
        },

        queryKey: [
            type,
            customer,
            company,
            paginationModel.page,
            paginationModel.pageSize,
        ],
    });

    const hasNextPage = !!overviewQuery.data?.links.next;

    return (
        <Grid2 container m={4}>
            {overviewQuery.data?.items && (
                <Box sx={{ height: 671, width: "100%" }}>
                    <DataGrid
                        columns={columns}
                        disableRowSelectionOnClick
                        getRowId={(row) => row.function_type_id!}
                        initialState={{
                            pagination: {
                                meta: {
                                    hasNextPage: hasNextPage,
                                },
                                paginationModel: { page: 0, pageSize: 100 },
                                rowCount: -1,
                            },
                        }}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        paginationMode="server"
                        paginationModel={paginationModel}
                        rows={overviewQuery.data?.items}
                        slots={{ toolbar: DataGridToolbar }}
                    />
                </Box>
            )}
        </Grid2>
    );
}
