import { Alert, Box, Button, Grid2, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

export function LoginPage({ error }: { error?: Error }) {
    const { signinRedirect } = useAuth();
    const { t } = useTranslation("login");
    return (
        <Grid2
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            spacing={0}
            sx={{ minHeight: "100vh" }}
        >
            <Grid2 size={3}>
                <h3>Please login</h3>
                <Stack spacing={2}>
                    {error && (
                        <Alert severity="error" variant="outlined">
                            {error.name} : {error.message}
                            <code>
                                <pre>{error.stack}</pre>
                            </code>
                            <code>
                                <pre>
                                    authority:{" "}
                                    {import.meta.env.VITE_OAUTH_AUTHORITY}
                                </pre>
                                <pre>
                                    client_id:{" "}
                                    {import.meta.env.VITE_OAUTH_CLIENT_ID}
                                </pre>
                                <pre>
                                    redirect_uri:{" "}
                                    {import.meta.env.VITE_OAUTH_REDIRECT_URL}
                                </pre>
                            </code>
                        </Alert>
                    )}
                    <Box>
                        <Button
                            onClick={async () => {
                                await signinRedirect();
                            }}
                        >
                            {t("login")}
                        </Button>
                    </Box>
                </Stack>
            </Grid2>
        </Grid2>
    );
}
