import {
    GridActionsCellItem,
    GridColDef,
    GridValidRowModel,
} from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { Icon } from "@theme/icon";
import { useTranslation } from "react-i18next";

import { getCommonApiClient } from "../../api";

export function useDataGridColumns<T extends GridValidRowModel>({
    company,
    customer,
}: {
    company: string;
    customer: string;
}): GridColDef<T>[] {
    const { t } = useTranslation("form");
    const deleteMutation = useMutation({
        mutationFn: async (id: string) => {
            const client = await getCommonApiClient();
            await client.delete_function_type({
                cash_customer: customer,
                company_id: company,
                function_type_id: id,
            });
        },
    });
    return [
        {
            field: "actions",
            getActions: ({ id }) => {
                return [
                    <Link
                        params={{
                            formId: `${id}`,
                        }}
                        to="/form/$formId"
                    >
                        <GridActionsCellItem
                            icon={
                                <Icon color="#67A870" name="Edit" size="sm" />
                            }
                            label={t("edit")}
                        />
                    </Link>,
                    <GridActionsCellItem
                        icon={<Icon color="#DB6B7D" name="Trash" size="sm" />}
                        label={t("delete")}
                        onClick={() => {
                            deleteMutation.mutate(`${id}`);
                        }}
                    />,
                ];
            },
            type: "actions",
        },
        { field: "job_function_id", headerName: "ID", width: 300 },
        {
            editable: false,
            field: "job_function",
            headerName: "Job Function",
            width: 150,
        },
        {
            editable: false,
            field: "description",
            headerName: t("columns.description"),
            width: 200,
        },
        {
            editable: false,
            field: "no_mailing",
            headerName: t("columns.noMailing"),
            width: 150,
        },
        {
            editable: false,
            field: "unique_presence",
            headerName: t("columns.uniquePresence"),
            width: 150,
        },
        {
            editable: false,
            field: "created_at",
            headerName: t("columns.createdAt"),
            width: 200,
        },
        {
            editable: false,
            field: "updated_at",
            headerName: t("columns.updatedAt"),
            width: 200,
        },
    ];
}
