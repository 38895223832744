import { Button, Grid2, Menu, Stack, Typography } from "@mui/material";
import { Icon, IconName } from "@theme/index";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

import DropdownListMenuSection from "./dropdown-list-menu-section";
import { MenuSectionProps } from "./dropdown-list-menu-section";

export default function DropdownListMenu({
    firstColumnMenuSections,
    icon,
    name,
    secondColumnMenuSections,
}: {
    firstColumnMenuSections: MenuSectionProps[];
    icon: IconName;
    name?: string;
    secondColumnMenuSections?: MenuSectionProps[];
}) {
    return (
        <PopupState popupId="user-popup-menu" variant="popover">
            {(popupState) => (
                <>
                    <Button {...bindTrigger(popupState)}>
                        <Icon color="white" name={icon} size="md" />
                        {name && (
                            <Typography
                                sx={{ color: "white", paddingLeft: 2 }}
                                variant="body2"
                            >
                                {name}
                            </Typography>
                        )}
                    </Button>
                    <Menu
                        {...bindMenu(popupState)}
                        MenuListProps={{
                            sx: {
                                minWidth: "30em",
                            },
                        }}
                    >
                        <Grid2 container gap={2}>
                            <Grid2 size={"grow"}>
                                <Stack>
                                    {firstColumnMenuSections.map((section) => (
                                        <DropdownListMenuSection
                                            heading={section.heading}
                                            icon={section.icon}
                                            items={section.items}
                                            key={section.heading}
                                        />
                                    ))}
                                </Stack>
                            </Grid2>
                            {secondColumnMenuSections && (
                                <Grid2 size={"grow"}>
                                    <Stack>
                                        {secondColumnMenuSections.map(
                                            (section) => (
                                                <DropdownListMenuSection
                                                    heading={section.heading}
                                                    icon={section.icon}
                                                    items={section.items}
                                                    key={section.heading}
                                                />
                                            ),
                                        )}
                                    </Stack>
                                </Grid2>
                            )}
                        </Grid2>
                    </Menu>
                </>
            )}
        </PopupState>
    );
}
