import type { z } from "zod";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    List,
    ListItem,
    Stack,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { Icon } from "../../theme/icon";
import { ColumnMenuSchema } from "./subnavigation";

export function MegaMenuColumn({
    column,
}: {
    column: z.infer<typeof ColumnMenuSchema>;
}) {
    const { t } = useTranslation("root");
    return (
        <>
            {column.title && (
                <Typography
                    color={"primary"}
                    flexShrink={0}
                    fontSize={"16px"}
                    fontWeight={"bold"}
                    sx={{
                        borderBottom: "1px solid #dadada",
                        paddingBottom: "0.1em",
                    }}
                    variant="h6"
                >
                    <Stack direction="row" height={"24px"} spacing={1}>
                        <Box sx={{ paddingTop: "3px" }}>
                            <Icon
                                name={column.icon as keyof typeof Icon}
                                size="xs"
                            />
                        </Box>
                        <span>{column.title}</span>
                    </Stack>
                </Typography>
            )}
            <List sx={{ paddingRight: "2em" }}>
                {column.items.map((item, index) => {
                    if ("menu" in item) {
                        return (
                            <Accordion
                                key={`${column.title}-menu-${index}`}
                                square
                                sx={{
                                    boxShadow: "none",
                                    fontSize: "14px",
                                }}
                            >
                                <AccordionSummary
                                    aria-controls="panel1-content"
                                    expandIcon={
                                        <Icon name="ChevronDown" size="sm" />
                                    }
                                    id="panel1-header"
                                >
                                    {t("mega-menu.specificFunctions")}
                                </AccordionSummary>
                                <AccordionDetails sx={{ paddingTop: 0 }}>
                                    <ListItem
                                        key={`inlinemenu-${item.menu[0].link}`}
                                        sx={{ paddingTop: 0 }}
                                    >
                                        <List>
                                            {item.menu.map((subitem) => (
                                                <ListItem
                                                    key={subitem.link}
                                                    sx={{ fontSize: "14px" }}
                                                >
                                                    {subitem.label}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </ListItem>
                                </AccordionDetails>
                            </Accordion>
                        );
                    }
                    return (
                        <ListItem
                            key={item.label}
                            sx={{
                                fontSize: "14px",
                            }}
                        >
                            {item.label}
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
}
