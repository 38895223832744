import { useDraggable } from "@dnd-kit/core";
import { Grid2 } from "@mui/material";

export default function Draggable({
    children,
    id,
}: {
    children?: React.ReactNode;
    id: string;
}) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: id,
    });
    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          }
        : undefined;

    return (
        <Grid2 ref={setNodeRef} sx={style} {...listeners} {...attributes}>
            {children}
        </Grid2>
    );
}
