import { createRoute } from "@tanstack/react-router";

import { LoginPage } from "../pages/login-page";
import rootRoute from "./__root";

const loginRoute = createRoute({
    component: LoginPage,
    getParentRoute: () => rootRoute,
    path: "/login",
});

export default loginRoute;
