import type { CssVarsThemeOptions } from "@mui/material/styles";

import { components } from "./components";
import { darkPalette, lightPalette } from "./palette";
import { typography } from "./typography";

const theme: CssVarsThemeOptions = {
    colorSchemes: {
        dark: { palette: darkPalette },
        light: { palette: lightPalette },
    },
    components: components,
    shape: {
        borderRadius: 8,
    },
    typography: typography,
};

export default theme;
