import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
    Typography,
} from "@mui/material";
import { Icon } from "@theme/icon";
import { useTranslation } from "react-i18next";

import { CompanyListButton } from "./company-list-button";

export function CompanyAccordion({
    companies = [],
    customer,
    handleClose,
}: {
    companies?: { company_id: string; company_name: string }[];
    customer: { id: string; name: string };
    handleClose: () => void;
}) {
    const { t } = useTranslation("root");
    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<Icon name="ChevronDown" size="sm" />}
            >
                <Stack direction="row" spacing={2}>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        {customer.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "10pt",
                        }}
                    >
                        {companies.length}{" "}
                        {companies.length > 1
                            ? t("general:companies")
                            : t("general:company")}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack my={1} spacing={2}>
                    {companies.map((company) => (
                        <CompanyListButton
                            handleClose={handleClose}
                            id={company.company_id}
                            key={company.company_id}
                            name={company.company_name}
                        />
                    ))}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
